import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { firebase } = config.public
  try {
    // Firebase の設定が正しく読み込まれているか確認
    if (!firebase?.config) {
      throw new Error('Firebase configuration is missing')
    }

    // Initialize Firebase
    const app = initializeApp(firebase.config)

    // Initialize Firebase Authentication
    const auth = getAuth(app)

    if (typeof window !== 'undefined') {
      // Analytics の初期化を試みる
      try {
        getAnalytics(app)
      } catch (analyticsError) {
        Sentry.captureException(analyticsError)
      }
    }

    return {
      provide: {
        firebaseApp: app,
        firebaseAuth: auth,
      },
    }
  } catch (error) {
    Sentry.captureException(error)
  }
})
