import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import accessed_45global from "/usr/src/app/middleware/accessed.global.ts";
import fetch_45session_45global from "/usr/src/app/middleware/fetch-session.global.ts";
import init_45search_45menu_45global from "/usr/src/app/middleware/init-search-menu.global.ts";
import redirect_45trailing_45slash_45global from "/usr/src/app/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  accessed_45global,
  fetch_45session_45global,
  init_45search_45menu_45global,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-clinics": () => import("/usr/src/app/middleware/redirect-clinics.ts"),
  "redirect-doctor": () => import("/usr/src/app/middleware/redirect-doctor.ts"),
  "redirect-f-clinic": () => import("/usr/src/app/middleware/redirect-f-clinic.ts"),
  "redirect-section": () => import("/usr/src/app/middleware/redirect-section.ts"),
  "redirect-station": () => import("/usr/src/app/middleware/redirect-station.ts"),
  "redirect-treatment": () => import("/usr/src/app/middleware/redirect-treatment.ts")
}