import {
  format,
  parseISO,
  differenceInMilliseconds,
  differenceInDays,
} from 'date-fns'

type DateInput = Date | string | number | null | undefined

const formatDateBase = (date: DateInput, formatString: string): string => {
  if (!date) return ''
  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)
  return format(parsedDate, formatString)
}

export const formatDateShort = (date: DateInput): string => {
  return formatDateBase(date, 'yyyy/M/d')
}

export const formatDateFull = (date: DateInput): string => {
  return formatDateBase(date, 'yyyy/MM/dd')
}

export const formatDateCustom = (
  date: DateInput,
  formatString: string
): string => {
  return formatDateBase(date, formatString)
}

export const formatJaDateYearMonth = (date: DateInput): string => {
  return formatDateBase(date, 'yyyy年M月')
}

export const formatRelativeTime = (date: DateInput): string => {
  if (!date) return ''
  const now = new Date()
  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date)
  const diff = differenceInMilliseconds(now, parsedDate)
  const diffHours = diff / (1000 * 60 * 60)
  const diffDays = diff / (1000 * 60 * 60 * 24)

  // https://www.figma.com/design/OLwZzKQHlyldmHCMT0xTB2/%E3%82%AD%E3%83%AA%E3%82%A4%E3%83%AA%E3%83%9D%E6%9C%AC%E4%BD%93?node-id=25128-101955&m=dev
  if (diffHours < 24) {
    return `${Math.floor(diffHours)}時間前`
  } else if (diffDays < 7) {
    return `${Math.floor(diffDays)}日前`
  } else if (diffDays < 14) {
    return `1週間前`
  } else if (diffDays < 21) {
    return `2週間前`
  } else if (diffDays < 28) {
    return `3週間前`
  } else if (diffDays < 32) {
    return `4週間前`
  } else if (diffDays < 365) {
    return `${Math.floor(diffDays / 30)}ヶ月前`
  } else {
    return `${Math.floor(diffDays / 365)}年前`
  }
}

export const formatBrowseHistoryDate = (date: DateInput): string => {
  if (!date) return ''
  const dateObj = new Date(date)
  const today = new Date()
  const diff = differenceInDays(today, dateObj)

  if (diff === 0) {
    return '今日'
  } else if (diff === 1) {
    return '昨日'
  } else if (diff <= 7) {
    return `${diff}日前`
  } else {
    return `${dateObj.getMonth() + 1}月${dateObj.getDate()}日`
  }
}

const padTwo = (num: number) => {
  return num.toString().padStart(2, '0')
}

export const dateToTimeString = (date: Date) => {
  return `${padTwo(date.getUTCHours())}:${padTwo(date.getUTCMinutes())}`
}
