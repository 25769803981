import * as Sentry from '@sentry/nuxt'

export default defineNuxtPlugin(() => {
  if (typeof window !== 'undefined') {
    window.addEventListener('unhandledrejection', (event) => {
      const error = event.reason
      const isModuleImportError =
        error instanceof TypeError &&
        (error.message.includes(
          'Failed to fetch dynamically imported module'
        ) ||
          error.message.includes('Importing a module script failed') ||
          error.message.includes('Loading chunk') ||
          error.message.includes('Loading CSS chunk'))

      if (isModuleImportError) {
        // エラーが発生した要素を特定
        const errorElement = document.activeElement
        const componentName =
          errorElement
            ?.closest('[data-v-component]')
            ?.getAttribute('data-v-component') || 'unknown'

        // URLからチャンク名を抽出
        const chunkMatch = error.message.match(/chunk '(.+?)'/)
        const chunkName = chunkMatch ? chunkMatch[1] : 'unknown'

        Sentry.captureException(error, {
          level: 'error',
          tags: {
            type: 'dynamic_import_error',
            url: window.location.href,
            errorType: error.name,
            errorMessage: error.message,
            componentName,
            chunkName,
          },
          extra: {
            timestamp: new Date().toISOString(),
            userAgent: navigator.userAgent,
            stack: error.stack,
            networkType: (navigator as any).connection?.type || 'unknown',
            effectiveType:
              (navigator as any).connection?.effectiveType || 'unknown',
            // DOMの状態も記録
            htmlAtError: errorElement?.outerHTML || 'not available',
            path: window.location.pathname,
            // Vueのルート情報
            routeName: (window as any)?.$nuxt?.$route?.name || 'unknown',
          },
        })
      }
    })
  }
})
